import Vue from 'vue';
import Vuex from 'vuex';
import _ from 'lodash';
import axios from 'axios';

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        activeBu: null,
        buList: [],
        slides: null,
        news: null,
        events: null,
        resources: null,
    },
    mutations: {
        changeBu(state, bu) {
            state.activeBu = bu;
        },
    },
    actions: {
        async fetchData({ dispatch }) {
            dispatch('fetchBu');
            dispatch('fetchSlides');
            dispatch('fetchNewsList');
            dispatch('fetchEventList');
            dispatch('fetchResourceList');
        },
        async fetchBu({ state }) {
            state.buList = await axios.get('https://directus.gosu.bar/2945/items/bu?fields=*')
                .then(({ data }) => _.chain(data.data)
                    .filter((bu) => {
                        const isProduction = process.env.NODE_ENV === 'production';
                        return isProduction ? bu.display : bu.displaystaging;
                    })
                    .map((bu) => bu.name)
                    .value());
        },
        async fetchSlides({ state }) {
            state.slides = await axios.get('https://directus.gosu.bar/2945/items/slides', {
                params: {
                    fields: '*, image.*, bu.*',
                },
            })
                .then(({ data }) => _.map(data.data, (slide) => ({
                    ...slide,
                    bu: slide.bu?.name,
                    image: slide.image.data.full_url,
                })))
                .catch((error) => {
                    // handle error
                    console.log(error);
                    return [];
                });
        },
        async fetchNewsList({ state }) {
            state.news = await axios.get('https://directus.gosu.bar/2945/items/news', {
                params: {
                    fields: '*, image.*, links.*, videos.*, bu.*',
                    sort: 'sort,-date',
                    'filter[status][in]': _.split(process.env.VUE_APP_DATA_STATUS, ','),
                },
            })
                .then(({ data }) => _.map(data.data, (item) => ({
                    ...item,
                    bu: item.bu?.name,
                    image: item.image.data.full_url,
                    videos: _.map(item.videos, (video) => video.url),
                })))
                .catch((error) => {
                    // handle error
                    console.log(error);
                    return [];
                });
        },
        async fetchEventList({ state }) {
            state.events = await axios.get('https://directus.gosu.bar/2945/items/events', {
                params: {
                    fields: '*, image.*, content_image.*, videos.*, banner.*, bu.*, links.*',
                    sort: '-created_on',
                    'filter[status][in]': _.split(process.env.VUE_APP_DATA_STATUS, ','),
                },
            })
                .then(({ data }) => _.map((data.data), (item) => {
                    const bu = item.bu?.name || '';
                    const image = item.image?.data.full_url || '';
                    const contentImage = item.content_image?.data.full_url || '';
                    const banner = item.banner?.data.full_url || '';
                    return {
                        ...item,
                        bu,
                        image,
                        contentImage,
                        banner,
                    };
                }))
                .catch((error) => {
                    // handle error
                    console.log(error);
                    return [];
                });
        },
        async fetchResourceList({ state }) {
            state.resources = await axios.get('https://directus.gosu.bar/2945/items/health_resource', {
                params: {
                    fields: '*, image.*,line_image.*, videos.*, bu.*',
                    sort: 'sort,-created_on',
                },
            })
                .then(({ data }) => _.chain(data.data)
                    .filter((resource) => {
                        const isProduction = process.env.NODE_ENV === 'production';
                        return isProduction ? resource.display : resource.displaystaging;
                    })
                    .map((item) => ({
                        ...item,
                        bu: item.bu?.name,
                        image: item.image.data.full_url,
                    }))
                    .value())
                .catch((error) => {
                    // handle error
                    console.log(error);
                    return [];
                });
            // console.log('state.resources', state.resources);
        },
        async fetchNews({ state, dispatch }, id) {
            if (_.isEmpty(state.news)) await dispatch('fetchNewsList');
            // eslint-disable-next-line eqeqeq
            const found = _.find(state.news, (news) => news.id == id);
            return found;
        },
        async fetchEvent({ state, dispatch }, id) {
            if (_.isEmpty(state.events)) await dispatch('fetchEventList');
            // eslint-disable-next-line eqeqeq
            const found = _.find(state.events, (event) => event.id == id);
            return found;
        },
        async fetchResources({ state, dispatch }, id) {
            if (_.isEmpty(state.resources)) await dispatch('fetchResourceList');
            // eslint-disable-next-line eqeqeq
            const found = _.find(state.resources, (resource) => resource.id == id);
            return found;
        },
    },
    getters: {
        filteredNews(state) {
            return state.activeBu === null
                ? state.news
                : _.filter(state.news, (item) => item.bu === state.activeBu);
        },
        filteredEvents(state) {
            return state.activeBu === null
                ? state.events
                : _.filter(state.events, (item) => item.bu === state.activeBu);
        },
        filteredResources(state) {
            return state.activeBu === null
                ? state.resources
                : _.filter(state.resources, (item) => item.bu === state.activeBu);
        },
    },
    modules: {
    },
});
