import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '@/views/Home.vue';
import News from '@/views/News.vue';
import NewsDetail from '@/views/NewsDetail.vue';
import Events from '@/views/Events.vue';
import EventDetail from '@/views/EventDetail.vue';
import Resources from '@/views/Resources.vue';
import ResourceDetail from '@/views/ResourceDetail.vue';

Vue.use(VueRouter);

const routes = [
    {
        path: '/',
        name: 'Home',
        component: Home,
        meta: { tab: 'Home', gtm: '首頁' },
    },
    {
        path: '/news',
        name: 'News',
        component: News,
        meta: { tab: 'News', title: '醫學新知列表', gtm: '醫學新知列表' },
    },
    {
        path: '/news/:id',
        name: 'NewsDetail',
        component: NewsDetail,
        meta: { tab: 'News', dynamicTitle: true, gtm: '醫學新知' },
    },
    {
        path: '/events',
        name: 'Events',
        component: Events,
        meta: { tab: 'Events', title: '精選活動列表', gtm: '精選活動列表' },
    },
    {
        path: '/events/:id',
        name: 'EventDetail',
        component: EventDetail,
        meta: { tab: 'Events', dynamicTitle: true, gtm: '精選活動' },
    },
    {
        path: '/resources',
        name: 'Resources',
        component: Resources,
        meta: { tab: 'Resources', title: '衛教資源列表', gtm: '衛教資源列表' },
    },
    {
        path: '/resource/:id',
        name: 'ResourceDetail',
        component: ResourceDetail,
        meta: { tab: 'Resources', dynamicTitle: true, gtm: '精選活動' },
    },
];

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition;
        }
        return { x: 0, y: 0 };
    },
});

export default router;
