import VueGtm from '@gtm-support/vue2-gtm';
import Vue from 'vue';
import router from '@/router';

Vue.use(VueGtm, {
    id: process.env.VUE_APP_GTM_ID,
    defer: false,
    enabled: true,
    debug: true, // Whether or not display console logs debugs (optional)
    loadScript: true,
    vueRouter: router, // Pass the router instance to automatically sync with router (optional)
    ignoredViews: ['homepage'], // Don't trigger events for specified router names (case insensitive) (optional)
    trackOnNextTick: false, // Whether or not call trackView in Vue.nextTick
});
