<template>
    <v-container class="px-6 pb-6">
        <v-sheet v-if="resource">
            <div class="mt-5 links">
                <div
                    class="link"
                    v-if="resource.pdf_download"
                    @click="downloadPdf(resource.pdf_download_url)"
                >
                    <span class="arrow-left"></span>
                    <div class="text">點我下載全文</div>
                </div>
            </div>
            <pdf-view
                v-if="resource.pdf"
                class="mt-4"
                :class="{
                    'pdf-content-height':
                        resource.pdf_type == 'SIZED_CONTAINER',
                }"
                :url="resource.pdf"
                :embedMode="resource.pdf_type"
            />

            <v-sheet class="mt-4">
                <v-responsive
                    class="mt-4"
                    :aspect-ratio="16 / 9"
                    v-for="(video, i) in resource.videos"
                    :key="i"
                >
                    <div class="video-name">{{ video.name }}</div>
                    <iframe
                        width="100%"
                        height="100%"
                        :src="video.url"
                        allowfullscreen
                        webkitallowfullscreen
                        mozallowfullscreen
                    ></iframe>
                </v-responsive>
            </v-sheet>
            <click-more
                route="Resources"
                :bu="resource.bu"
                text="查看其他衛教資源"
            />
        </v-sheet>
    </v-container>
</template>

<script>
import { mapActions } from 'vuex';
import PDFView from '@/components/PDFView.vue';
import ClickMore from '@/components/ClickMore.vue';

export default {
    components: {
        'pdf-view': PDFView,
        ClickMore,
    },
    data() {
        return {
            resource: null,
        };
    },
    computed: {},
    created() {},
    async mounted() {
        this.resource = await this.fetchResources(this.$route.params.id);
        this.$root.$emit('title', this.resource.title);
    },
    methods: {
        ...mapActions(['fetchResources']),
        downloadPdf(link) {
            window.open(link, '_blank');
        },
    },
};
</script>

<style lang="scss" scoped>
@import "@/scss/index.scss";
.pdf-content-height {
    height: 60vh !important;
}

.video-name {
    font-size: 12px;
}

.links {
    display: flex;
    flex-wrap: wrap;
    .link {
        $height: 34px;
        height: $height;
        display: flex;
        margin: 4px 12px 4px 0;
        .arrow-left {
            width: 0;
            height: 0;
            border-top: $height/2 solid transparent;
            border-bottom: $height/2 solid transparent;
            border-right: $height/2 solid $accent-color;
        }
        .text {
            width: fit-content;
            padding: 5px 12px;
            background-color: $accent-color;
            color: white;
            font-weight: bold;
            font-size: 16px;
        }
    }
}
</style>
