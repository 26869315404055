<template>
    <v-app class="app">
        <!-- 當loading為true時，不顯示任何內容 -->
        <template v-if="!loading">
            <!-- 加載完畢，根據valid的值決定顯示內容 -->
            <v-app-bar app max-width="960" class="header">
                <header-bar />
            </v-app-bar>

            <template v-if="valid">
                <!-- 驗證通過，顯示主要內容 -->
                <v-main>
                    <router-view />
                    <disclaimer />
                </v-main>
                <v-footer class="footer" app padless outlined max-width="960">
                    <tab-bar />
                </v-footer>
            </template>

            <template v-if="inValid">
                <!-- 驗證未通過，顯示提示信息 -->
                <v-main>
                    <v-layout class="mt-4" justify-center>請先取得輝瑞藥廠授權</v-layout>
                </v-main>
            </template>
        </template>
        <!-- 當loading為true時，可以選擇顯示加載指示器或保持空白 -->
        <template v-else>
            <!-- 加載中的提示或空白 -->
        </template>
    </v-app>
</template>
<!-- <template>
    <v-app class="app">
        <v-app-bar app max-width="960" class="header">
            <header-bar />
        </v-app-bar>
        <template v-if="valid">
            <v-main>
                <router-view />
                <disclaimer />
            </v-main>
            <v-footer class="footer" app padless outlined max-width="960">
                <tab-bar />
            </v-footer>
        </template>
        <template v-else>
            <v-main>
                <v-layout class="mt-4" justify-center> 請先取得輝瑞藥廠授權 </v-layout>
            </v-main>
        </template>
    </v-app>
</template> -->

<script>
import { mapActions } from 'vuex';
import liff from '@line/liff';
import HeaderBar from '@/components/HeaderBar.vue';
import TabBar from '@/components/TabBar.vue';
import Disclaimer from '@/components/Disclaimer.vue';
import hcp from '@/plugins/hcp';

export default {
    name: 'App',
    data: () => ({
        valid: false,
        inValid: false,
        loading: true, // 新增loading狀態
    }),
    components: { HeaderBar, TabBar, Disclaimer },
    methods: {
        ...mapActions(['fetchData']),
        async initLiff() {
            this.loading = true; // 開始驗證，設置loading為true
            if (process.env.NODE_ENV === 'development') {
                // 開發環境下跳過 LINE Login
                this.valid = true;
                this.loading = false;
                return;
            }
            try {
                await liff.init({ liffId: process.env.VUE_APP_LIFF_ID });
                await liff.ready;
                if (!liff.isLoggedIn()) {
                    liff.login({
                        redirectUri: `${window.location.origin}${this.$route.path}`,
                    });
                } else if (!(await hcp.verify(liff.getAccessToken()))) {
                    // 驗證失敗的處理...
                    this.inValid = true;
                } else {
                    const profile = await liff.getProfile();
                    window.dataLayer.push({
                        event: 'login',
                        userId: profile.userId,
                    });
                    this.valid = true; // 驗證成功
                }
            } catch (error) {
                console.error('LIFF Initialization failed:', error);
            } finally {
                this.loading = false; // 無論驗證成功或失敗，結束驗證，設置loading為false
            }
        },
    },
    mounted() {
        this.initLiff();
        this.fetchData();
    },
};
</script>
<style lang="scss" scoped>
$breakdown: 960px;

.app {
    max-width: $breakdown;
    margin: 0 auto;
    box-shadow: 0 0 2em rgba(0, 0, 0, 0.5);
}

.footer {
    box-shadow: 0 2px 14px 0 rgba(0, 0, 0, 0.5);
    background: #fff;
}

body {
    background: #eee;
}

@media screen and (min-width: $breakdown) {
    .header {
        margin-left: calc((100% - 960px) / 2);
    }

    .footer {
        margin-left: calc((100% - 960px) / 2);
    }
}
</style>
