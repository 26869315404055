import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        themes: {
            light: {
                primary: '#395592',
                // secondary: '#b0bec5',
                accent: '#0190ff',
                // error: '#b71c1c',
            },
        },
    },
});
