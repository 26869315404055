<template>
    <v-container class="px-0 pt-1 pb-0">
        <bu-filter />
        <v-list class="pt-0 mt-2 pb-0">
            <v-list-item-group>
                <v-list-item
                    class="mb-4 mx-2 pl-0 item"
                    v-for="(item, i) in filteredNews"
                    :key="i"
                    @click="clickItem(item)"
                >
                    <v-list-item-avatar
                        class="ma-0"
                        width="180"
                        height="135"
                        :tile="true"
                    >
                        <v-img :src="item.image"></v-img>
                    </v-list-item-avatar>
                    <v-list-item-content class="ml-3">
                        <div class="title">{{ item.title }}</div>
                        <div class="date">{{ item.date }}</div>
                        <div class="abstract mt-3">{{ item.abstract }}</div>
                    </v-list-item-content>
                </v-list-item>
            </v-list-item-group>
            <template v-if="filteredNews == null">
                <v-list-item
                    class="mb-4 mx-2 pl-0 item"
                    v-for="i in 4"
                    :key="i"
                >
                    <v-skeleton-loader type="image" width="180" height="135" />
                    <v-list-item-content class="ml-3 pa-0">
                        <v-skeleton-loader type="text@2" />
                        <v-skeleton-loader type="paragraph" class="mt-3" />
                    </v-list-item-content>
                </v-list-item>
            </template>
        </v-list>
    </v-container>
</template>

<script>
import { mapGetters } from 'vuex';
import BuFilter from '@/components/BuFilter.vue';

export default {
    components: { BuFilter },
    computed: {
        ...mapGetters(['filteredNews']),
    },
    methods: {
        clickItem(item) {
            this.$router.push({ name: 'NewsDetail', params: { id: item.id } });
        },
    },
};
</script>

<style lang="scss" scoped>
@import "@/scss/index.scss";
.title,
.abstract {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    -webkit-line-clamp: 3;
}
.title {
    color: $dark-text;
    font-size: 14px !important;
    font-weight: 500;
    line-height: normal;
}
.abstract,
.date {
    color: $light-text;
    font-size: 12px;
    font-weight: 500;
}
.item {
    border: 1px solid #ead4b9;
}
</style>
