<template>
    <div class="header-bar">
        <img
            v-if="!isTopPage"
            class="back"
            src="@/assets/icon/back.png"
            @click="back"
        />
        <div class="title" v-if="title">{{ title }}</div>
        <img class="logo" v-else src="@/assets/logo.png" />
    </div>
</template>
<script>
export default {
    data() {
        return {
            dynamicTitle: null,
        };
    },
    computed: {
        title() {
            return this.$route.meta.dynamicTitle ? this.dynamicTitle : this.$route.meta.title;
        },
        isTopPage() {
            return this.$route.name === 'Home';
        },
    },
    methods: {
        back() {
            return this.$router.go(-1);
        },
    },
    mounted() {
        this.$root.$on('title', (title) => {
            this.dynamicTitle = title;
        });
    },
};
</script>

<style lang="scss" scoped>
@import "@/scss/index.scss";
.header-bar {
    text-align: center;
    width: 100%;
    .title {
        color: $primary-color;
        font-size: 18px;
        font-weight: 500;
        line-height: 1.33;
        padding: 0 20px;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
    }
    .logo {
        width: 72px;
        height: 32px;
    }
    .back {
        position: absolute;
        left: 8px;
        top: 8px;
        width: 40px;
        height: 40px;
    }
}
</style>
