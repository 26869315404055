<template>
    <div class="tab-bar">
        <div
            class="tabs"
            :class="activeClass(tab)"
            v-for="(tab, i) in tabs"
            :key="i"
            @click="clickTab(tab)"
        >
            <img :src="require('@/assets/tabs/' + iconImage(tab))" />
            <div>{{ tab.text }}</div>
        </div>
    </div>
</template>

<script>
export default {
    computed: {
        tabs() {
            return [
                { text: '首頁', icon: 'home', name: 'Home' },
                { text: '醫學新知', icon: 'news', name: 'News' },
                { text: '精選活動', icon: 'event', name: 'Events' },
                { text: '衛教資源', icon: 'resource', name: 'Resources' },
            ];
        },
        isActiveTab() {
            return (tab) => this.$route.meta.tab === tab.name;
        },
        activeClass() {
            return (tab) => ({
                active: this.isActiveTab(tab),
            });
        },
    },
    methods: {
        iconImage(tab) {
            return this.isActiveTab(tab) ? `${tab.icon}.png` : `${tab.icon}_active.png`;
        },
        clickTab(tab) {
            if (this.$route.name !== tab.name) {
                this.$router.push({ name: tab.name });
            }
        },
    },
};
</script>

<style lang="scss" scoped>
@import "@/scss/index.scss";
.tab-bar {
    padding: 4px 6px;
    display: flex;
    flex-direction: row;
    width: 100vw;
    background: #fff;
    .tabs {
        display: flex;
        flex-direction: column;
        flex: 1;
        align-items: center;
        justify-content: center;
        padding: 2px 0;
        img {
            margin-top: 4px;
            width: 20px;
            height: 20px;
        }
        div {
            font-size: 12px;
            color: $primary-color;
        }
        &.active {
            background: $accent-color;
            border-radius: 3px;
            div {
                color: white;
            }
        }
    }
}
</style>
