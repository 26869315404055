<template>
    <div class="more mx-auto mt-6" @click="clickMore">
        <div class="text">{{text}}</div>
        <div class="avatar">
            <v-icon color="black">mdi-chevron-right</v-icon>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        route: String,
        bu: String,
        text: String,
    },
    methods: {
        clickMore() {
            this.$router.push({ name: this.route, query: { bu: this.bu } });
        },
    },
};
</script>

<style lang="scss" scoped>
@import "@/scss/index.scss";
.more {
    height: 34px;
    width: 272px;
    border-radius: 7px;
    background-color: $accent-color;
    padding: 5px 8px;
    display: flex;
    flex-direction: row;
    .text {
        flex: 1;
        margin-left: 24px;
        font-size: 18px;
        font-weight: bold;
        color: white;
        text-align: center;
        align-self: center;
    }
    .avatar {
        width: 24px;
        height: 24px;
        background-color: white;
        border-radius: 50%;
    }
}
</style>
