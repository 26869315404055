<template>
    <v-responsive :aspect-ratio="829 / 363">
        <v-carousel cycle :show-arrows="true" :hide-delimiters="true" height="100%">
            <v-carousel-item v-for="(slide, i) in buSlides" :key="i">
                <v-img :src="slide.image"></v-img>
            </v-carousel-item>
        </v-carousel>
    </v-responsive>
</template>

<script>
import _ from 'lodash';
import { mapState } from 'vuex';

export default {
    props: {
        slides: Array,
        height: {
            type: Number,
            default: 200,
        },
    },
    computed: {
        ...mapState(['buList', 'activeBu']),
        buSlides() {
            const showedSlides = {};
            return _.chain(this.slides).filter((slide) => {
                if (_.isEmpty(this.activeBu)) {
                    const couldShow = !_.has(showedSlides, slide.bu);
                    showedSlides[slide.bu] = slide;
                    return couldShow;
                }
                return slide.bu === this.activeBu;
            }).sortBy((slide) => _.indexOf(this.buList, slide.bu)).value();
        },
    },
};
</script>

<style lang="scss" scoped>
</style>
