<template>
    <v-container class="px-6 pb-6">
        <v-sheet v-if="news">
            <div v-if="news.links.length > 0" class="mt-5 links">
                <div class="link" v-for="(link, i) in news.links" :key="i" @click="clickLink(link)">
                    <span class="arrow-left"></span>
                    <div class="text">{{ link.text }}</div>
                </div>
            </div>
            <!-- <v-img class="mt-4" :src="news.contentImage" /> -->
            <v-sheet v-if="news.videos.length > 0" class="mt-4">
                <v-responsive
                    class="mt-4"
                    :aspect-ratio="16 / 9"
                    v-for="(video, i) in news.videos"
                    :key="i"
                >
                    <iframe
                        width="100%"
                        height="100%"
                        :src="video"
                        allowfullscreen
                        webkitallowfullscreen
                        mozallowfullscreen
                    ></iframe>
                </v-responsive>
            </v-sheet>
            <pdf-view class="mt-4" :url="news.pdf" />
            <click-more route="News" :bu="news.bu" text="查看其他醫學新知" />
        </v-sheet>
    </v-container>
</template>

<script>
import { mapActions } from 'vuex';
import PDFView from '@/components/PDFView.vue';
import ClickMore from '@/components/ClickMore.vue';

export default {
    components: {
        'pdf-view': PDFView,
        ClickMore,
    },
    data() {
        return {
            news: null,
        };
    },
    methods: {
        ...mapActions(['fetchNews']),
        clickLink(link) {
            window.open(link.url, '_blank');
        },
    },
    async mounted() {
        this.news = await this.fetchNews(this.$route.params.id);
        this.$root.$emit('title', this.news.title);
    },
};
</script>
<style lang="scss" scoped>
@import "@/scss/index.scss";

</style>
