<template>
    <v-chip-group column class="bu-group">
        <div class="px-2">
            <v-chip
                class="chip"
                v-for="(chip, i) in chips"
                :key="i"
                @click="selectFilter(chip)"
                :class="activeClass(chip)"
            >
                {{ chip.text }}
            </v-chip>
        </div>
    </v-chip-group>
</template>

<script>
import { mapMutations, mapState } from 'vuex';

export default {
    computed: {
        ...mapState(['buList', 'activeBu']),
        chips() {
            return [
                { text: 'All', value: null },
                ...this.buList.map((bu) => ({ text: bu, value: bu })),
            ];
        },
        activeClass() {
            return (chip) => ({
                activeBuChip: chip.value === this.activeBu,
            });
        },
    },
    methods: {
        ...mapMutations(['changeBu']),
        selectFilter(chip) {
            const bu = chip.value;
            this.changeBu(bu);
            this.triggerEvent(bu);
        },
        triggerEvent(bu) {
            const value = bu || '全部';
            this.$gtm.trackEvent({
                event: 'filter_bu',
                部門: value,
            });
        },
    },
    created() {
        const { bu } = this.$route.query;
        if (bu) this.changeBu(bu);
    },
};
</script>

<style lang="scss" scoped>
@import "@/scss/index.scss";

.bu-group{
    text-align: center;
}
.chip {
    border-radius: 4px !important;
    background: $primary-color !important;
    color: #fff !important;
    &.activeBuChip {
        background: $accent-color !important;
    }
}
</style>
<style lang="scss">
.v-chip.v-size--default {
    font-size: 16px !important;
}
</style>
