<template>
    <div class="notice pa-4">
        此訊息僅提供給有意願獲取相關醫學新知之專業醫療人員，且受著作財產權保護，請勿任意複製、轉載或分享於其他大眾媒體平台。
        <div>PP-UNP-TWN-0239-202408</div>
    </div>
</template>

<script>
export default {

};
</script>

<style lang="scss" scoped>
.notice {
    background: #eee;
    color: #9b9b9b;
    font-size: 12px;
    font-weight: bold;
    letter-spacing: -0.24px;
}
</style>
