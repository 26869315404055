<template>
    <v-container class="px-0 pt-1">
        <bu-filter />
        <slider class="mt-2" :slides="slides" />
        <div class="divider"></div>
        <item-group :items="filteredNews" section="醫學新知" resource="News" />
        <div class="divider mt-3"></div>
        <item-group
            :items="filteredEvents"
            section="精選活動"
            resource="Event"
        />
        <div class="divider mt-3"></div>
        <item-group :items="filteredResources" section="衛教資源" resource="Resource" />

    </v-container>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import Slider from '@/components/Slider.vue';
import ItemGroup from '@/components/ItemGroup.vue';

export default {
    name: 'Home',
    components: {
        Slider,
        ItemGroup,
    },
    computed: {
        ...mapState(['slides', 'news', 'events', 'resources']),
        ...mapGetters(['filteredNews', 'filteredEvents', 'filteredResources']),
    },
};
</script>
<style lang="scss" scoped>
.divider {
    height: 8px;
    width: 100%;
    background: #0190ff;
}
</style>
