<template>
    <v-sheet>
        <div class="d-flex">
            <div class="indicator my-auto"></div>
            <v-col class="section py-2">{{ section }}</v-col>
            <v-spacer />
            <v-col class="more py-2 text-right" @click="clickMore"
                >查看更多</v-col
            >
        </div>
        <v-slide-group v-if="items">
            <v-slide-item
                class="ml-4 items"
                v-for="(item, i) of items"
                :key="i"
            >
                <div @click="clickItem(item)">
                    <v-img :src="item.image" height="120px" width="180px" />
                    <div class="title">{{ item.title }}</div>
                </div>
            </v-slide-item>
            <div class="ml-4" />
        </v-slide-group>
        <v-slide-group v-else>
            <v-slide-item class="ml-4 items" v-for="i in 2" :key="i">
                <div>
                    <v-skeleton-loader type="image" height="135" width="180" />
                    <v-skeleton-loader type="title" />
                </div>
            </v-slide-item>
            <div class="ml-4" />
        </v-slide-group>
    </v-sheet>
</template>

<script>
import _ from 'lodash';

export default {
    props: {
        section: String,
        items: Array,
        resource: String,
    },
    methods: {
        clickItem(item) {
            this.$router.push({
                name: `${this.resource}Detail`,
                params: { id: item.id },
            });
        },
        clickMore() {
            const name = _.last(this.resource) === 's'
                ? this.resource
                : `${this.resource}s`;
            this.$router.push({ name });
        },
    },
};
</script>

<style lang="scss" scoped>
@import '@/scss/index.scss';
.section {
    font-size: 16px;
    font-weight: 500;
}
.more {
    font-size: 14px;
    font-weight: 500;
}
.indicator {
    width: 24px;
    height: 10px;
    background-color: $primary-color;
}
.items {
    border: solid 2px #b9e7ea;
    width: 180px;
    .title {
        font-size: 14px !important;
        font-weight: 500;
        text-align: center;
        line-height: 25px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
    }
}
</style>
