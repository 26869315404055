<template>
    <v-container class="px-6 pb-6 event-detail">
        <v-sheet v-if="event">
            <div v-if="event.links" class="mt-5 links">
                <div class="link" v-for="(link, i) in event.links" :key="i"
                    @click="clickLink(link)">
                    <span class="arrow-left"></span>
                    <div class="text">{{ link.text }}</div>
                </div>
            </div>
            <v-sheet class="mt-6">
                <v-responsive
                    class="mt-4"
                    :aspect-ratio="16 / 9"
                    v-for="(video, i) in event.videos"
                    :key="i"
                >
                    <iframe
                        width="100%"
                        height="100%"
                        :src="video.url"
                        allowfullscreen
                        webkitallowfullscreen
                        mozallowfullscreen
                    ></iframe>
                </v-responsive>
            </v-sheet>
            <pdf-view v-if="event.pdf" class="mt-3" :url="event.pdf" />
            <template v-else>
                <v-container class="pa-6">
                    <v-row class="title">{{ event.title }}</v-row>
                    <v-row class="date mt-2">{{ event.date }}</v-row>
                    <v-row class="mt-2 content" v-html="event.content"></v-row>
                </v-container>
                <v-img v-if="event.contentImage" class="mt-3 mb-8" :src="event.contentImage" />
            </template>
            <click-more route="Events" :bu="event.bu" text="查看其他精選活動" />
        </v-sheet>
    </v-container>
</template>

<script>
import { mapActions } from 'vuex';
import PDFView from '@/components/PDFView.vue';
import ClickMore from '@/components/ClickMore.vue';

export default {
    components: {
        'pdf-view': PDFView,
        ClickMore,
    },
    data() {
        return {
            event: null,
            tab: null,
        };
    },
    methods: {
        ...mapActions(['fetchEvent']),
        clickLink(link) {
            window.open(link.url, '_blank');
        },
        clickMore() {
            this.$router.push({ name: 'Events' });
        },
    },
    async mounted() {
        this.event = await this.fetchEvent(this.$route.params.id);
        this.$root.$emit('title', this.event.title);
    },
};
</script>

<style lang="scss" scoped>
@import "@/scss/index.scss";
.event-detail {
    color: $light-text;
    font-weight: 500;
    .title {
        color: $dark-text;
        font-size: 18px !important;
    }
    .date {
        font-size: 16px !important;
    }
    .content {
        line-height: 1.33;
    }
    .video-description {
        font-size: 12px;
        line-height: 1.33;
    }
}
</style>
